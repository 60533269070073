import React, {useState, useEffect} from 'react';
import {Form, Input, DatePicker, Select, Button, Typography, message, Alert, Modal} from 'antd';
import {useParams, useNavigate, Link, useLocation} from 'react-router-dom';
import dayjs from 'dayjs';

import FacturaService from '../services/FacturaService';
import AlquilerService from '../services/AlquilerService';

const {Title, Text} = Typography;

const FacturaForm = () => {
    const {id, idAlquiler} = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [form] = Form.useForm();
    const [alquileres, setAlquileres] = useState([]);
    const [factura, setFactura] = useState({});
    const [errorFecha, setErrorFecha] = useState('');
    const [isFacturado, setIsFacturado] = useState(false);
    const [alquilerEncontrado, setAlquilerEncontrado] = useState(null);

    // Obtener datos iniciales de alquileres y factura
    useEffect(() => {
        const cargarDatos = async () => {
            try {
                const alquileresData = await AlquilerService.obtenerTodos();
                setAlquileres(alquileresData.data);

                if (idAlquiler) {
                    const alquiler = alquileresData.data.find(a => a.id === idAlquiler);
                    setAlquilerEncontrado(alquiler); // Actualiza el estado
                    form.setFieldsValue({
                        alquilerId: idAlquiler,
                        importeNeto: alquiler?.montoInicial || '',
                        fechaEmision: dayjs(),
                    });
                }

                if (id) {
                    const {data} = await FacturaService.obtenerPorId(id);
                    form.setFieldsValue({
                        fechaEmision: data.fechaEmision ? dayjs(data.fechaEmision) : '',
                        importeNeto: data.importeNeto,
                        comentarios: data.comentarios,
                        alquilerId: data.alquiler?.id || '',
                    });
                    setFactura(data);
                    setIsFacturado(data.isFacturado);
                }
            } catch (error) {
                message.error(`Error al cargar datos: ${error.message}`);
            }
        };

        cargarDatos();
    }, [id, idAlquiler, form]);


    // Validar diferencia de días para facturación
    useEffect(() => {
        if (factura.fechaEmision && !isFacturado) {
            const diferenciaDias = dayjs().diff(factura.fechaEmision, 'day');
            setErrorFecha(
                diferenciaDias > 5
                    ? `No se puede facturar porque tiene más de 5 días de diferencia con hoy (${dayjs().format('DD/MM/YYYY')})`
                    : ''
            );
        }
    }, [factura.fechaEmision, isFacturado]);

    const handleSubmit = async (values) => {
        try {
            const facturaData = {
                ...values,
                fechaEmision: values.fechaEmision?.toISOString() || new Date().toISOString(),
            };
            if (id) {
                await FacturaService.actualizar(id, facturaData);
                message.success('Factura actualizada');
            } else {
                await FacturaService.guardar(facturaData);
                message.success('Factura creada');
            }
            navigate('/facturas');
        } catch (error) {
            message.error(`Error: ${error.message}`);
        }
    };

    const handleFacturar = async () => {
        if (errorFecha) {
            message.error(errorFecha);
            return;
        }

        Modal.confirm({
            title: 'Confirmación',
            content: '¿Desea guardar los cambios y luego facturar?',
            okText: 'Guardar y Facturar',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await handleSubmit(form.getFieldsValue());
                    await FacturaService.facturar(id);
                    message.success('Factura generada con éxito.');
                    navigate(`/facturas/${id}/ver`);
                } catch (error) {
                    message.error(`Error al generar la factura: ${error.message}`);
                }
            },
        });
    };

    const handleDelete = async () => {
        if (isFacturado) {
            message.error('La factura ya fue emitida, no se puede borrar.');
            return;
        }

        Modal.confirm({
            title: 'Eliminar Factura',
            content: '¿Estás seguro de que deseas eliminar esta factura?',
            okText: 'Eliminar',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await FacturaService.eliminar(id);
                    message.warning('Factura eliminada.');
                    navigate('/facturas');
                } catch (error) {
                    message.error(`Error al eliminar la factura: ${error.message}`);
                }
            },
        });
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '2rem'}}>
            <Title
                level={2}>{alquilerEncontrado ? 'Crear factura para ' + alquilerEncontrado.empresa.razonSocial : id ? 'Editar factura' : 'Crear factura'}</Title>

            {id && (
                <div style={{marginBottom: '20px'}}>
                    {isFacturado ? (
                        <>
                            <Text type="success">FACTURA GENERADA</Text>
                            <Link to={`${location.pathname}/ver`}>
                                <Button type="link">Ver factura</Button>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Text type="danger">SIN FACTURAR</Text>
                            {errorFecha && <Alert message={errorFecha} type="error" showIcon closable/>}
                            <Button
                                onClick={handleFacturar}
                                type="primary"
                                disabled={!!errorFecha}
                                style={{marginTop: '20px'}}
                            >
                                Facturar
                            </Button>
                        </>
                    )}
                </div>
            )}

            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Fecha emisión" name="fechaEmision">
                    <DatePicker onChange={(date) => setFactura((prev) => ({...prev, fechaEmision: date}))}/>
                </Form.Item>
                <Form.Item
                    label="Importe Neto"
                    name="importeNeto"
                    rules={[{required: true, message: 'Ingrese el importe neto'}]}
                >
                    <Input type="number"/>
                </Form.Item>
                <Form.Item label="Comentarios" name="comentarios">
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    label="Alquiler"
                    name="alquilerId"
                    rules={[{required: true, message: 'Seleccione un alquiler'}]}
                >
                    <Select placeholder="Seleccione un alquiler">
                        {alquileres.map((alquiler) => (
                            <Select.Option key={alquiler.id} value={alquiler.id}>
                                {alquiler.cliente?.nombre || "SIN CLIENTE"} - {alquiler.contenedor?.codigo || "SIN CONTENEDOR"}
                            </Select.Option>
                        ))}
                    </Select>

                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Guardar
                </Button>
                {id && (
                    <>
                        <Button style={{margin: '10px'}} onClick={() => navigate(`/facturas/${id}/ver`)}>
                            Ver factura
                        </Button>
                        <Button type="danger" onClick={handleDelete} style={{color: 'orangered'}}>
                            Eliminar
                        </Button>
                    </>
                )}
            </Form>
        </div>
    );
};

export default FacturaForm;
