// src/components/layout/PrintableProtectedLayout.js
import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import html2pdf from 'html2pdf.js'; // Importa html2pdf.js
import { Button } from 'antd'; // Importa el componente Button de Ant Design

const PrintableProtectedLayout = () => {
  const contentRef = useRef();

  const generatePDF = () => {
    if (contentRef.current) {
      html2pdf()
        .set({
          margin: [0.5, 0.2, 0.5, 0.2],
          filename: 'documento.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(contentRef.current)
        .save();
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button type="primary" onClick={generatePDF} style={{ marginBottom: '10px' }}>
        Descargar PDF
      </Button>
      <div ref={contentRef}>
        <Outlet />
      </div>
    </div>
  );
};

export default PrintableProtectedLayout;
