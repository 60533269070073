// src/services/ContenedorService.js
import axiosInstance from '../utils/axiosInstance';
import config from '../config';

const API_URL = `${config.API_URL}/facturas`;

class FacturaService {
    obtenerTodos() {
        return axiosInstance.get(API_URL);
    }

    obtenerPorId(id) {
        return axiosInstance.get(`${API_URL}/${id}`);
    }

    verFacturaCDoc(id) {
        return axiosInstance.get(`${API_URL}/${id}/ver`);
    }

    facturar(id, facturaData) {
        return axiosInstance.post(`${API_URL}/${id}/facturar`, facturaData);
    }

    obtenerCobranzas(id){
        return axiosInstance.get(`${API_URL}/${id}/cobranzas`);
    }

    guardar(factura) {
        return axiosInstance.post(API_URL, factura);
    }

    actualizar(id, factura) {
        return axiosInstance.put(`${API_URL}/${id}`, factura);
    }

    autofacturar(id) {
        return axiosInstance.get(`${API_URL}/autofacturar`);
    }

    eliminar(id) {
        return axiosInstance.delete(`${API_URL}/${id}`);
    }

    hola159159() {
        return axiosInstance.get(`${API_URL}/hola159159`);
    }
}

export default new FacturaService();