import {jwtDecode} from 'jwt-decode';
import axiosInstance from '../utils/axiosInstance';

class AuthService {
  async login(email, password) {
    // Verificamos si ya hay un token y si está vencido antes de proceder
    if (!this.isAuthenticated()) {
      this.logout(); // Si el token actual está vencido, limpiamos el almacenamiento
    }

    // Procedemos con el login
    const response = await axiosInstance.post('/auth/login', { email, password });
    const token = response.data.token;

    if (token) {
      localStorage.setItem('token', token);
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    try {
      // Decodificamos el token para obtener la información de expiración
      const { exp } = jwtDecode(token);

      // Comparamos la fecha de expiración con la actual
      if (Date.now() >= exp * 1000) {
        this.logout(); // Si está vencido, hacemos logout
        return false;
      }

      return true; // El token es válido
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      this.logout();
      return false;
    }
  }
}

export default new AuthService();
