import React, {useState} from 'react';
import '../assets/css/landingStyles.css';

import logo from '../assets/images/logo.png';
import slide1 from '../assets/images/slider/slide-1.jpg';
import slide2 from '../assets/images/slider/slide-2.jpg';
import reefer40ft from '../assets/images/realreefer40.jpg';
import container20ft from '../assets/images/20ftcontainer.jpg';
import truckTrailer from '../assets/images/trucktrailer.jpg';
import LandingNavbar from './layout/LandingNavbar';

const LandingPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const [modalCaption, setModalCaption] = useState('');

    const toggleSidebar = () => setSidebarOpen(prev => !prev);
    const closeSidebar = () => setSidebarOpen(false);

    const openModal = (src, caption) => {
        setModalImageSrc(src);
        setModalCaption(caption);
        setModalOpen(true);
    };
    const closeModal = () => setModalOpen(false);

    return (
        <div className="App">
            {/* Sidebar/menu */}
            <LandingNavbar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} toggleSidebar={toggleSidebar}/>

            {/* PAGE CONTENT */}
            <div className="w3-main" style={{marginLeft: 340, marginRight: 40}}>
                <div className="w3-container" style={{marginTop: 80}} id="home">
                    <h1 className="w3-jumbo w3-hide-small"><b>Reefers del Sur</b></h1>
                    <div className="w3-center">
                        <img className="w3-jumbo w3-hide-medium w3-hide-large" src={logo} alt="Reefers del Sur"
                             style={{width: '80%'}}/>
                    </div>
                    <h1 className="w3-xxxlarge w3-text-blue"><b>Alquiler de contenedores</b></h1>
                    <hr style={{width: 50, border: '5px solid lightskyblue'}} className="w3-round"/>
                </div>

                {/* Photo grid */}
                <div className="w3-row-padding">
                    <div className="w3-half">
                        <img src={slide1} style={{width: '100%'}}
                             onClick={() => openModal(slide1, 'Contenedor marítimo de 40 pies')}
                             alt="Contenedor marítimo de 40 pies"/>
                    </div>
                    <div className="w3-half">
                        <img src={slide2} style={{width: '100%'}}
                             onClick={() => openModal(slide2, 'Hidrogrúa en acción contra 4 toneladas')}
                             alt="Hidrogrúa en acción contra 4 toneladas"/>
                    </div>
                </div>

                {/* Modal for full size images */}
                {modalOpen && (
                    <div className="w3-modal w3-black" style={{paddingTop: 0}} onClick={closeModal}>
                        <span className="w3-button w3-black w3-xxlarge w3-display-topright">×</span>
                        <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
                            <img src={modalImageSrc} className="w3-image" alt=''/>
                            <p>{modalCaption}</p>
                        </div>
                    </div>
                )}

                {/* Services Section */}
                <div className="w3-container" id="servicios" style={{marginTop: 75}}>
                    <h1 className="w3-xxxlarge w3-text-blue"><b>Servicios</b></h1>
                    <hr style={{width: 50, border: '5px solid lightskyblue'}} className="w3-round"/>
                    <p>Somos una empresa con sede en CABA con una larga trayectoria en alquiler, venta y transporte de
                        contenedores de frío y estándar de todas las medidas existentes en el mercado.</p>
                    <p>Nuestros equipos cuentan con soporte técnico y asistencia en todo el país en menos de 48 horas y
                        una fiabilidad máxima.</p>
                </div>
                {/* The Team */}
                <div className="w3-row-padding">
                    <div className="w3-col m4 w3-margin-bottom">
                        <div className="w3-light-grey">
                            <img src={reefer40ft} alt="Reefer 40 pies" style={{width: '100%'}}/>
                            <div className="w3-container">
                                <h3>Contenedores refrigerados</h3>
                                <p className="w3-opacity">20 y 40 pies</p>
                                <p>De 6 y 12 metros con temperaturas entre -25°C y +30°C.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col m4 w3-margin-bottom">
                        <div className="w3-light-grey">
                            <img src={container20ft} alt="Contenedor 20 pies" style={{width: '100%'}}/>
                            <div className="w3-container">
                                <h3>Contenedores estándar</h3>
                                <p className="w3-opacity">40 pies</p>
                                <p>Versatilidad para almacenamiento y traslado de productos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col m4 w3-margin-bottom">
                        <div className="w3-light-grey">
                            <img src={truckTrailer} alt="Camión de transporte" style={{width: '100%'}}/>
                            <div className="w3-container">
                                <h3>Transporte</h3>
                                <p className="w3-opacity">A todo el país</p>
                                <p>Llevamos el contenedor a tu empresa en camiones con hidrogrúa.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contact Section */}
                <div className="w3-container" id="contacto" style={{marginTop: 75}}>
                    <h1 className="w3-xxxlarge w3-text-blue"><b>Contacto</b></h1>
                    <hr style={{width: 50, border: '5px solid lightskyblue'}} className="w3-round"/>
                    <div className="w3-bar-block w3-xlarge">
                        <a href="mailto:contacto@reefersdelsur.com" className="w3-bar-item w3-button w3-hover-black">
                            <i className="bi bi-envelope w3-margin-right"></i> contacto@reefersdelsur.com
                        </a>
                        <a href="https://wa.me/5491158379669" className="w3-bar-item w3-button w3-hover-teal">
                            <i className="bi bi-whatsapp w3-margin-right"></i> +54 9 11 5837-9669
                        </a>
                        <a href="https://www.facebook.com/reefersdelsur/"
                           className="w3-bar-item w3-button w3-hover-indigo">
                            <i className="bi bi-facebook w3-margin-right"></i> /reefersdelsur
                        </a>
                        <a href="https://www.instagram.com/reefersdelsur"
                           className="w3-bar-item w3-button w3-hover-pink">
                            <i className="bi bi-instagram w3-margin-right"></i> @reefersdelsur
                        </a>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="w3-light-grey w3-container w3-padding-32" style={{marginTop: 75, paddingRight: 58}}>
                <p className="w3-right w3-xlarge">
                    <img src={logo} alt="Reefers del Sur" style={{width: '92px'}}/>Reefers del Sur
                </p>
            </div>
        </div>
    );
};

export default LandingPage;
