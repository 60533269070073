import React, {useState, useEffect} from 'react';
import {List, Button, Typography, message, Modal} from 'antd';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import FacturaService from '../services/FacturaService';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Asegúrate de importar el idioma para mostrar los días en español.
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);

// Configuración para que los días de la semana se muestren en español y abreviados
dayjs.updateLocale('es', {
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
});

const {Title} = Typography;

const FacturaList = () => {
    const [facturas, setFacturas] = useState([]);

    useEffect(() => {
        handleObtenerFacturas();
    }, []);

    const handleObtenerFacturas = () => {
        FacturaService.obtenerTodos()
            .then(response => setFacturas(response.data))
            .catch(error => {
                console.error(error); // Para depuración
                message.error('Error al obtener las facturas: ' + (error.response?.data?.message || error.message || 'Desconocido'));
            });
    };

    const handleAutofacturar = () => {
        Modal.confirm({
            title: '¿Estás seguro de que deseas autofacturar?',
            content: `
            Esta acción va a generar todas las facturas correspondientes al día de hoy sin enviar a la AFIP.
            
            Las facturas generadas acá no se duplican nunca, sin importar cuántas veces se presione el botón.
            
            Este proceso se hace automáticamente cada día a las 8AM.
            `,
            okText: 'Iniciar autofacturación',
            cancelText: 'Cancelar',
            onOk: () => {
                FacturaService.autofacturar()
                    .then(() => {
                        message.success('Facturas generadas exitosamente.');
                        handleObtenerFacturas();
                    })
                    .catch(error => {
                        console.error(error); // Para depuración
                        message.error('Error al autofacturar: ' + (error.response?.data?.message || error.message || 'Desconocido'));
                    });
            },
        });

    };

    const handleHola159159 = () => {
        FacturaService.hola159159()
            .then(() => {
                message.success('OK.');
            })
            .catch(error => {
                console.error(error); // Para depuración
                message.error('Error al hola: ' + (error.response?.data?.message || error.message || 'Desconocido'));
            });
    }
    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Lista de Facturas</Title>
            <List
                bordered
                dataSource={facturas}
                renderItem={factura => (
                    <List.Item>
                        <Link to={`/facturas/${factura.id}`}>
                            <b>
                                {factura.fechaEmision
                                    ? dayjs(factura.fechaEmision).locale('es').format('ddd DD-MM-YYYY')
                                    : 'Fecha no disponible'}
                            </b> - {factura.razonSocialCliente}<br/>
                            {new Intl.NumberFormat('es-AR', {
                                style: 'currency',
                                currency: 'ARS'
                            }).format(factura.importeTotal || 0)}
                        </Link>
                        {/* Ícono al final del item */}
                        {factura.isFacturado ? (
                            <CheckCircleFilled style={{color: 'lightgreen', marginLeft: 10}}/>
                        ) : (
                            <CloseCircleFilled style={{color: 'orange', marginLeft: 10}}/>
                        )}
                    </List.Item>
                )}
            />
            <Link to="/facturas/nuevo">
                <Button type="primary">Crear</Button>
            </Link>

            <Button type="dashed" style={{margin: 16}} onClick={handleAutofacturar}>Generar facturas de hoy</Button>
            <Button type="secondary" onClick={handleHola159159}>Hola</Button>

        </div>
    );
};

export default FacturaList;
