import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ConfigPage = ({ isDarkMode, toggleTheme }) => {
    return (
        <div style={{ maxWidth: 500, margin: 'auto', padding: '2rem' }}>
            <h1 style={{ textAlign: 'center' }}>Configuración</h1>
            <Button type="primary" onClick={toggleTheme}>
                Cambiar a {isDarkMode ? 'Modo Claro' : 'Modo Oscuro'}
            </Button>
            <hr />
            <Link to="/home" className="w3-bar-item w3-button">
                <i className="bi bi-house w3-margin-right"></i> Menú principal
            </Link>
        </div>
    );
};

export default ConfigPage;
