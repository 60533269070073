// src/components/HomePage.js
import React from 'react';

function HomePage() {

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <h1>
                Bienvenido
            </h1>
            <p>Iniciaste sesión correctamente.
                <i className="bi bi-check-circle-fill" style={{marginLeft: '10px', color: 'lightgreen'}}></i>
            </p>

            <h4>¿A dónde vamos?</h4>
            <div className="w3-bar-block">

                <a href="/modelosContrato" className="w3-bar-item w3-button">
                    <i className="bi bi-file-earmark-text w3-margin-right"></i> Contratos
                </a>
                <a href="/alquileres" className="w3-bar-item w3-button">
                    <i className="bi bi-building w3-margin-right"></i> Alquileres
                </a>
                <a href="/clientes" className="w3-bar-item w3-button">
                    <i className="bi bi-people w3-margin-right"></i> Clientes
                </a>
                <a href="/empresas" className="w3-bar-item w3-button">
                    <i className="bi bi-shop w3-margin-right"></i> Empresas
                </a>
                <a href="/cobranzas" className="w3-bar-item w3-button">
                    <i className="bi bi-cash-coin w3-margin-right"></i> Cobranzas
                </a>
                <a href="/contenedores" className="w3-bar-item w3-button">
                    <i className="bi bi-box-seam w3-margin-right"></i> Contenedores
                </a>
                <a href="/facturas" className="w3-bar-item w3-button">
                    <i className="bi bi-receipt w3-margin-right"></i> Facturas
                </a>
                <a href="/emisores" className="w3-bar-item w3-button">
                    <i className="bi bi-envelope-paper w3-margin-right"></i> Emisores
                </a>
                <a href="/reportes" className="w3-bar-item w3-button">
                    <i className="bi bi-graph-up w3-margin-right"></i> Reportes
                </a>
                <a href="/presupuestos" className="w3-bar-item w3-button">
                    <i className="bi bi-journal-text w3-margin-right"></i> Presupuestos
                </a>
                <a href="/logout" className="w3-bar-item w3-button w3-hover-red" style={{color: 'red'}}>
                    <i className="bi bi-box-arrow-right w3-margin-right"></i> Cerrar sesión
                </a>
                <hr/>
                <a href="/config" className="w3-bar-item w3-button">
                    <i className="bi bi-gear w3-margin-right"></i> Configuración
                </a>
            </div>
        </div>
    );
}

export default HomePage;
